import React from "react";
// import { Route, Routes } from "react-router-dom";
// import ProtectedRoute from "./components/00-Global/ProtectedRoute";
// import LoginPage from "./views/A-auth/pages/login";
// import jwt_decode from "jwt-decode"
// import Auth from "./views/A-auth";

// import RegisterPage from "./views/A-auth/pages/register";
// import ForgotPasswordPage from "./views/A-auth/pages/forgotPassword";
// import NewPasswordPage from "./views/A-auth/pages/newPassword";
// import FirstAddress from "./views/A-auth/pages/firstAddress"

// import Home from './views/Home'
// import Dashboard from "./views/D-dashboard";
// import Marketplace from "./views/C-marketplace";
// import MainDashboard from "./views/D-dashboard/pages/main";
// import MainMarketplace from "./views/C-marketplace/pages/main";
// import Admin from "./views/B-admin";

// import SingleProducer from "./views/C-marketplace/pages/producers/singleProducer";
// import SingleOrder from "./views/D-dashboard/pages/orders/singleOrder";
// import SingleDelivery from "./views/D-dashboard/pages/deliveries/singleDelivery";

// import Billings from "./views/D-dashboard/pages/billings";
// import Deliveries from "./views/D-dashboard/pages/deliveries";
// import Orders from "./views/D-dashboard/pages/orders";
// import Profil from "./views/D-dashboard/pages/profil";
// import Stock from "./views/D-dashboard/pages/stock";

// import Cart from "./views/C-marketplace/pages/cart/cart";
// import Tunnel from "./views/C-marketplace/pages/cart/tunnel";
// import Producers from "./views/C-marketplace/pages/producers";
// import OrderValidate from "views/F-redirectPages/orderValidate";
// import CartAndTunnelContent from "./views/C-marketplace/pages/cart";

// import NotFound from "views/F-redirectPages/notFound";

// import FAQ from "./views/G-faq";
// import CreateOrUpdateProduct from "./views/D-dashboard/pages/stock/createOrUpdateProduct";
// import SingleProduct from "views/D-dashboard/pages/stock/singleProduct";

// import UsersAdmin from "views/B-admin/pages/users";
// import SingleUser from "views/B-admin/pages/users/viewUser";
// import GalleryAdmin from "views/B-admin/pages/gallery";
// import WishlistAdmin from "views/B-admin/pages/wishlist";
// import ProductsAdmin from "views/B-admin/pages/products";
// import LabelAdmin from "views/B-admin/pages/labels";
// import SingleProductAdmin from "views/B-admin/pages/products/singleProduct";
// import NewOrUpadte from "views/B-admin/pages/products/newOrUpadte";
// import NewOrUpadteSubProduct from "views/B-admin/pages/products/subProducts/newOrUpadteSubProduct";
// import LogOutForce from "views/A-auth/pages/logOutForce";
// import Products from "views/C-marketplace/pages/products";
import { Typography, Button, Box } from "@mui/material";
import logo from 'assets/images/logos/logo.png';
import Customers from "components/09-Admin/customers/customers";

// const ROLES = {
//   'buyer': 'buyer',
//   'producer': 'producer',
//   'transformer': 'transformer',
//   'admin': 'admin'
// }

function App() {
  // const [user, setUser] = useState({});

  // const userInfo = (token) => {
  //   try {
  //     const theUser = jwt_decode(token)
  //     setUser(theUser);
  //   } catch (e) {
  //     console.log(e);
  //   }
  // }

  // useEffect(() => {
  //   const userToken = localStorage.getItem('user-token');
  //   if (userToken) userInfo(userToken)
  // }, []);

  return (
    <React.Fragment>
      <div style={{ height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
        <Box component="img" alt="Roz'ho" src={logo} sx={{height: 70, mr: 1 }} />
        <Typography variant="h2" gutterBottom>Roz'ho évolue</Typography>
        <Typography variant="subtitle1" sx={{ mb: 3 }}>Notre solution est en pleine phase d'évolution pour répondre mieux aux besoins du marché.</Typography>
        <Typography variant="body1" sx={{ mb: 2 }}>Si vous souhaitez en savoir plus n'hésitez pas à prendre contact avec nous : contact@rozho.fr</Typography>
        <Button variant="contained" href="mailto:contact@rozho.fr">Nous écrire</Button>
      </div>
      
    </React.Fragment>
  );
}
export default App;


// <Routes>
//         {/* !!!!!!!!!!!!!!!!!!!!!! PAGE NOT FOUND !!!!!!!!!!!!!!!!!!!!!! */}
//         <Route path='*' element={<NotFound userType={user.role} />}/>

//         {/* !!!!!!!!!!!!!!!!!!!!!! TEST POUR LA FAQ !  !!!!!!!!!!!!!!!!!!!!!! */}
//         <Route path='/faq'>
//           <Route path='' element={<FAQ user={user} />} />
//         </Route>

//         {/* !!!!!!!!!!!!!!!!!!!!!! CONNEXION ROUTES !!!!!!!!!!!!!!!!!!!!!! */}
//         <Route path='/connexion' element={<Auth />}>
//           <Route path='' element={<LoginPage setUser={setUser} />} />
//         </Route>
//         <Route path='/inscription' element={<Auth />}>
//           <Route path='' element={<RegisterPage setUser={setUser} />} />
//         </Route>
//         <Route path='/mot-de-passe-oublie' element={<Auth />}>
//           <Route path='' element={<ForgotPasswordPage />} />
//         </Route>
//         <Route path='/mot-de-passe-oublie/:token' element={<Auth />}>
//           <Route path='' element={<NewPasswordPage /> } />
//         </Route>
//         <Route path='/inscription/ajouter-adresse' element={<Auth />}>
//           <Route path='' element={
//             <ProtectedRoute roles={[ROLES.buyer, ROLES.transformer, ROLES.producer, ROLES.admin]} userType={user.role}>
//               <FirstAddress user={user} />
//             </ProtectedRoute>
//           } />
//         </Route>
//         {/* Route for log out the user */}
//         <Route path='/deconnexion' element={<LogOutForce />} />
//         {/* !!!!!!!!!!!!!!!!!!!!!! END CONNEXION ROUTES !!!!!!!!!!!!!!!!!!!!!! */}

//         {/* !!!!!!!!!!!!!!!!!!!!!! PROTECTED ROUTES !!!!!!!!!!!!!!!!!!!!!! */}
//         <Route path="/" element={<Home />}>
//           {/* Routes for marketPlace */}
//           <Route path='' element={<Marketplace />}>
//             <Route path='' element={
//               <ProtectedRoute roles={[ROLES.buyer, ROLES.transformer, ROLES.admin]} userType={user.role}>
//                 <MainMarketplace />
//               </ProtectedRoute>
//             } />
//             {/* Route in the shop */}
//             <Route path='producteurs'>
//               <Route path='' element={
//                 <ProtectedRoute roles={[ROLES.buyer, ROLES.transformer, ROLES.admin]} userType={user.role}>
//                   <Producers />
//                 </ProtectedRoute>
//               } />
//               <Route path=':id' element={
//                 <ProtectedRoute roles={[ROLES.buyer, ROLES.transformer, ROLES.admin]} userType={user.role}>
//                   <SingleProducer />
//                 </ProtectedRoute>
//               } />
//               {/* Route for preview the shop of a producer */}
//               <Route path='preview/:id' element={
//                 <ProtectedRoute roles={[ROLES.producer, ROLES.transformer, ROLES.admin]} userType={user.role}>
//                   <SingleProducer preview />
//                 </ProtectedRoute>
//               } />
//             </Route>

//             {/* Route after search, list of subProducts */}
//             <Route path='produits/:id' element={
//               <ProtectedRoute roles={[ROLES.buyer, ROLES.transformer, ROLES.admin]} userType={user.role}>
//                 <Products />
//               </ProtectedRoute>
//             } />
            
//             {/* Cart Route */}
//             <Route path='' element={<CartAndTunnelContent />}>
//               <Route path='panier' element={
//                 <ProtectedRoute roles={[ROLES.buyer, ROLES.transformer, ROLES.admin]} userType={user.role}>
//                   <Cart />
//                 </ProtectedRoute>
//               } />
//               <Route path='passer-commande' element={
//                 <ProtectedRoute roles={[ROLES.buyer, ROLES.transformer, ROLES.admin]} userType={user.role}>
//                   <Tunnel />
//                 </ProtectedRoute>
//               } />
//               <Route path='commande-validee' element={
//                 <ProtectedRoute roles={[ROLES.buyer, ROLES.transformer, ROLES.admin]} userType={user.role}>
//                   <OrderValidate />
//                 </ProtectedRoute>
//               } />
//             </Route>
            
//           </Route>

//           {/* Routes for dashbaord */}
//           <Route path='dashboard' element={<Dashboard />}>
//             <Route path='' element={
//               <ProtectedRoute roles={[ROLES.transformer, ROLES.producer, ROLES.admin]} userType={user.role}>
//                 <MainDashboard />
//               </ProtectedRoute>
//             }/>
//             {/* Stock routes */}
//             <Route path="stock">
//               <Route path='' element={
//                 <ProtectedRoute roles={[ROLES.transformer, ROLES.producer, ROLES.admin]} userType={user.role}>
//                   <Stock />
//                 </ProtectedRoute>
//               }/>
//               <Route path='produit/:id' element={
//                 <ProtectedRoute roles={[ROLES.transformer, ROLES.producer, ROLES.admin]} userType={user.role}>
//                   <SingleProduct />
//                 </ProtectedRoute>
//               }/>
//               <Route path='produit/:id/modifier' element={
//                 <ProtectedRoute roles={[ROLES.transformer, ROLES.producer, ROLES.admin]} userType={user.role}>
//                   <CreateOrUpdateProduct update />
//                 </ProtectedRoute>
//               }/>
//               <Route path='produit/nouveau' element={
//                 <ProtectedRoute roles={[ROLES.transformer, ROLES.producer, ROLES.admin]} userType={user.role}>
//                   <CreateOrUpdateProduct />
//                 </ProtectedRoute>
//               }/>
//             </Route>

//             {/* deliveries route */}
//             <Route path="commandes-recues">
//               <Route path='' element={
//                 <ProtectedRoute roles={[ROLES.transformer, ROLES.producer, ROLES.admin]} userType={user.role}>
//                   <Deliveries />
//                 </ProtectedRoute>
//               }/>
//               <Route path='commande=:id' element={
//                 <ProtectedRoute roles={[ROLES.transformer, ROLES.producer, ROLES.admin]} userType={user.role}>
//                   <SingleDelivery />
//                 </ProtectedRoute>
//               }/>
//             </Route>

//             {/* orders route */}
//             <Route path="commandes-effectuees">
//               <Route path='' element={
//                 <ProtectedRoute roles={[ROLES.transformer, ROLES.buyer, ROLES.admin]} userType={user.role}>
//                   <Orders />
//                 </ProtectedRoute>
//               }/>
//               <Route path=':id' element={
//                 <ProtectedRoute roles={[ROLES.transformer, ROLES.buyer, ROLES.admin]} userType={user.role}>
//                   <SingleOrder />
//                 </ProtectedRoute>
//               }/>
//             </Route>

//             {/* Profil route */}
//             <Route path="entreprise">
//               <Route path='' element={
//                 <ProtectedRoute roles={[ROLES.transformer, ROLES.buyer, ROLES.producer, ROLES.admin]} userType={user.role}>
//                   <Profil />
//                 </ProtectedRoute>
//               }/>
//             </Route>

//             {/* Profil route */}
//             <Route path="facturation">
//               <Route path='' element={
//                 <ProtectedRoute roles={[ROLES.transformer, ROLES.buyer, ROLES.producer, ROLES.admin]} userType={user.role}>
//                   <Billings />
//                 </ProtectedRoute>
//               }/>
//             </Route>
//           </Route>
//         </Route>

//         {/* Admin routes */}
//         <Route
//           path='/admin' 
//           element={
//             <ProtectedRoute roles={[ROLES.admin]} userType={user.role}>
//               <Admin />
//             </ProtectedRoute>
//           }
//         >
//           <Route index element={<p>Home</p>}/>

//           {/* MANAGE USERS */}
//           <Route path='utilisateurs'>
//             <Route path='' element={<UsersAdmin />}/>
//             <Route path=':id'>
//               <Route path='' element={<SingleUser />}/>
//               <Route path='sous_produit/nouveau' element={<NewOrUpadteSubProduct />}/>
//               <Route path='produit/:subProductId/modifier' element={<NewOrUpadteSubProduct update />}/>
//             </Route>
//           </Route>

//           {/* MANAGE API PRODUCT */}
//           <Route path='produits'>
//             <Route path='' element={<ProductsAdmin />}/>
//             <Route path='nouveau' element={<NewOrUpadte />}/>
//             <Route path=':id'>
//               <Route path='' element={<SingleProductAdmin />}/>
//               <Route path='mettre-a-jour' element={<NewOrUpadte update />}/>
//             </Route> 
//           </Route>

//           {/* MANAGE GALERIE IMG */}
//           <Route path='galerie' element={<GalleryAdmin />}/>

//           {/* MANAGE WISHLIST */}
//           <Route path='wishlist' element={<WishlistAdmin />}/>

//           {/* MANAGE USER LABELS REQUESTS */}
//           <Route path='labels' element={<LabelAdmin />}/>
//         </Route>

//         {/* !!!!!!!!!!!!!!!!!!!!!! END PROTECTED ROUTES !!!!!!!!!!!!!!!!!!!!!! */}

//       </Routes>