import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from 'reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import { Theme } from 'assets/elements/rozhoTheme';
import App from 'App';
import 'App.css';

const root = ReactDOM.createRoot(document.getElementById('root'));
const theme = Theme

root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}> 
      <BrowserRouter basename={'/'}>
        <App />
      </BrowserRouter>
    </ThemeProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
